<template>
  <v-container>
    <v-card v-for="(cr, i) of chaperoneRules" :key="i" class="mb-4">
      <v-card-title>Rules for Adult/Student Ratio (Grades: {{ cr ? cr.gradeLevels.join(', ') : '' }})</v-card-title>
      <v-form :readonly="!canEditSettings">
        <v-card-text>
          <v-row class="align-center">
            <v-col cols="12" md="12">
              <v-chip-group v-model="cr.gradeLevels" active-class="primary" column multiple>
                <v-chip value="All" @click.native="handleAll(i)" :disabled="!canEditSettings">All</v-chip>
                <v-chip
                  v-for="grade in filteredGrades[i]"
                  :key="grade"
                  :value="grade"
                  :disabled="!canEditSettings"
                  @click.native="clickGrade($event, i)"
                >
                  {{ grade }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-subheader class="inline">There must be</v-subheader>
              <v-text-field
                outlined
                v-model="cr.adultsPerStudents"
                class="inline text-field"
                type="number"
                min="0"
              ></v-text-field>
              <v-subheader class="inline">adult(s) for</v-subheader>
              <v-text-field
                outlined
                v-model="cr.numStudents"
                class="inline text-field"
                type="number"
                min="0"
              ></v-text-field>
              <v-subheader class="inline">or more students.</v-subheader>
            </v-col>
            <v-col cols="12">
              <v-subheader class="inline">There must be</v-subheader>
              <v-text-field
                outlined
                v-model="cr.adultsPerAdditional"
                class="inline text-field"
                type="number"
                min="0"
              ></v-text-field>
              <v-subheader class="inline">adult(s) for every additional</v-subheader>
              <v-text-field
                outlined
                v-model="cr.additionalStudents"
                class="inline text-field"
                type="number"
                min="0"
              ></v-text-field>
              <v-subheader class="inline">students.</v-subheader>
            </v-col>
            <v-col cols="12" md="12">
              <v-subheader>
                Do you require at least one female chaperone for female students and at least one male chaperone for
                male students?
              </v-subheader>
              <v-radio-group v-model="cr.requireSameGender" row class="ml-4">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import { GRADES } from '@common/constants';

const newChaperoneRules = {
  gradeLevels: [],
  adultsPerStudents: 0,
  numStudents: 0,
  adultsPerAdditional: 0,
  additionalStudents: 0,
  requireSameGender: false,
};

export default {
  inject: ['eventHub'],
  components: {},
  data() {
    return {
      chaperoneRules: [{ ...newChaperoneRules }],
      grades: GRADES,
      filteredGrades: {},
      usedGrades: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  created() {
    this.eventHub.$on('saveChaperoneRulesConfig', () => this.save());
    this.eventHub.$on('addChaperoneRule', () => this.addCR());
  },
  beforeDestroy() {
    this.eventHub.$off('saveChaperoneRulesConfig');
    this.eventHub.$off('addChaperoneRule');
  },
  async mounted() {
    await this.getTRConfig();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async getTRConfig() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.chaperoneRules && Array.isArray(this.trConfig.chaperoneRules))
        this.chaperoneRules = this.trConfig.chaperoneRules;
      this.getUsedGrades();
      this.filterGrades();
    },
    addCR() {
      if (this.usedGrades.length != this.grades.length) {
        this.chaperoneRules.push({ ...newChaperoneRules });
        this.filteredGrades[this.chaperoneRules.length - 1] = this.grades.filter((e) => !this.usedGrades.includes(e));
      } else this.$myalert.error('All grades have Chaperone Rules already');
    },
    async save() {
      try {
        const value = this.trConfig
          ? { ...this.trConfig, chaperoneRules: this.chaperoneRules }
          : { chaperoneRules: this.chaperoneRules };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.$myalert.success('Chaperone Rules saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    resetGrades() {
      this.grades = ['Pre-K', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    },
    getUsedGrades() {
      this.usedGrades = [];
      this.chaperoneRules.forEach((e) => (this.usedGrades = this.usedGrades.concat(e.gradeLevels)));
    },
    filterGrades() {
      for (let i = 0; i < this.chaperoneRules.length; i++) {
        this.filteredGrades[i] = this.grades.filter(
          (e) => !this.usedGrades.filter((g) => !this.chaperoneRules[i].gradeLevels.includes(g)).includes(e)
        );
        this.chaperoneRules[i].gradeLevels.push('');
        this.chaperoneRules[i].gradeLevels.pop();
      }
    },
    clickGrade(event, index) {
      if (this.usedGrades.includes(event.target.innerText))
        this.usedGrades.splice(this.usedGrades.indexOf(event.target.innerText), 1);
      else this.usedGrades.push(event.target.innerText);
      this.resetGrades(); // idk how or why grades are being removed from this.grades
      this.getUsedGrades();
      this.filterGrades();
    },
    handleAll(index) {
      this.chaperoneRules[index].gradeLevels.pop(); // remove 'All' from grade levels
      if (this.chaperoneRules[index].gradeLevels.length == this.filteredGrades[index].length) {
        this.chaperoneRules[index].gradeLevels = [];
        this.getUsedGrades();
      } else {
        this.chaperoneRules[index].gradeLevels = this.filteredGrades[index];
        this.usedGrades = this.grades;
      }
      this.filterGrades();
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
</style>
